var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"page-container"},[_c('div',{staticClass:"container-title-block"},[_c('div',{staticClass:"title-block-text"},[_vm._v("Топики")]),_c('div',{staticClass:"title-block-search"},[_c('SearchInput',{staticClass:"ml-4",attrs:{"label":"Поиск топиков"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"container-subtitle-block"},[_c('div',[_c('v-btn',{staticClass:"button-blue",attrs:{"depressed":""},on:{"click":function($event){return _vm.openDialog({}, true)}}},[_c('span',[_vm._v("Добавить топик")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"subtitle-block-count"},[_vm._v(" Всего топиков: "+_vm._s(_vm.paginationSupply.itemCount)+" ")])])]),_c('div',{staticClass:"container-table"},[_c('Table',{attrs:{"items":_vm.searchingItems,"headers":_vm.headers,"headerFixed":""},on:{"scrollMethod":_vm.checkForScrolling,"click:row":_vm.openDialog}})],1),_c('Dialog',{attrs:{"titleText":_vm.topicDialogObj.titleDialog,"refForm":'formtopic',"buttonsFooter":{
      success: {
        title: _vm.topicDialogObj.textButton,
        loading: true,
      },
      delete: {
        title: _vm.selectedItem.id ? 'Удалить топик' : null,
      },
    }},on:{"click:success":function($event){return _vm.savetopic(_vm.selectedItem.id)},"click:delete":function($event){return _vm.deleteTopic(_vm.selectedItem.id)}},scopedSlots:_vm._u([{key:"blockMain",fn:function(){return [_c('div',[_c('TextInput',{attrs:{"label":"Название топика","valid":""},model:{value:(_vm.selectedItem.name),callback:function ($$v) {_vm.$set(_vm.selectedItem, "name", $$v)},expression:"selectedItem.name"}})],1),_c('div',{staticClass:"dialog-items"},[_c('div',{ref:"valuesBlock",staticClass:"dialog-items-values"})])]},proxy:true}],null,false,3198057067),model:{value:(_vm.dialogtopic),callback:function ($$v) {_vm.dialogtopic=$$v},expression:"dialogtopic"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }